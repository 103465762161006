/**
 * Determine if a string is null or only contains whitespace.
 *
 * @param {string} string the string to test
 * @returns {boolean} true if null or only contains whitespace
 */
export function isBlank(string) {
    return !string || string.replace(/\s/g, "").length === 0;
}

/**
 * Determine if a string contains non-whitespace characters.
 *
 * @param {string} string the string to test
 * @returns {boolean} true if null or only contains whitespace
 */
export function isNotBlank(string) {
    return !isBlank(string);
}
